<script>
import Layout from "../../layouts/main";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
import axios from 'axios';

export default {

    components: {
        Layout,
        EasyDataTable,

    },
    data() {
        return {
            addStaff: false,
            archiveStaff: false,
            viewStaff: false,
            editStaff: false,
            selectFilterSort: null,
            headers: [
                { text: "Name", value: "staffname" },
                { text: "Emails", value: "staffemail" },
                { text: "Status", value: "staffstatus" },
                { text: "Action", value: "staffaction" },
            ],
            items: [
                // { "staffname": "Deepak", "staffemail": "deepak123@gmail.com", "staffstatus": "Inactive", "staffaction": "edit/delete" },
                // { "staffname": "Deepak", "staffemail": "deepak123@gmail.com", "staffstatus": "Inactive", "staffaction": "edit/delete" },
                // { "staffname": "Deepak", "staffemail": "deepak123@gmail.com", "staffstatus": "Inactive", "staffaction": "edit/delete" },
                // { "staffname": "Deepak", "staffemail": "deepak123@gmail.com", "staffstatus": "Inactive", "staffaction": "edit/delete" },
                // { "staffname": "Deepak", "staffemail": "deepak123@gmail.com", "staffstatus": "Inactive", "staffaction": "edit/delete" },
            ],
            arrItem: [],

        }
    },
    mounted() {
        this.showdata();
    },
    created() {
        this.arrItem = this.items
    },
    methods: {
        duplicateItem() {
            this.itemsss.push(Date.now())
        },
        showdata(){
        let arrList =[]
        axios.get('/applications/archived_staff_member/')
            .then(response => {
            
            const user_data = response.data;
            console.log('this is for yes check',user_data)
            let responseList = response.data;
                responseList.forEach(item => {
                // console.log('this i',item.status)
                let newItem = {
                    // applicantNum: item.application_no,
                    staffname:item.username,
                    staffemail:item.email,
                    staffstatus: "Inactive",
                    staffaction: "edit/delete"
    
                };
                arrList.push(newItem);
                });
                this.arrItem = arrList
            })
            .catch(error => {
            console.error(error);
            });
        }
    }
};

</script>

<template>
    <Layout>
        <main class="py-4">
            <div class="row mb-3 align-items-center">
                <div class="col">
                    <h2 class="font-size-22 text-theme-dark mb-0">Archived Staff Members</h2>
                </div>
                <div class="col-auto">
                    <div class="text-end">
                        <router-link to="/staff_users"
                            class="mb-0 fw-medium text-theme-dark d-flex align-items-center gap-2">Staff
                            members
                            <i class="bx bx-right-arrow-alt"></i>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <EasyDataTable :headers="headers" :items="arrItem" :search-value="searchValueTable" border-cell
                                theme-color="#df6a0d" :rows-per-page="10" buttons-pagination
                                table-class-name="table-custom-style">
                                <template #item-staffname="">
                                    <span class="fw-small">
                                        Not Updated
                                    </span>
                                </template>
                                <template #item-staffstatus="{ staffstatus }">
                                    <span class="badge badge-pill font-size-11" :class="{
                                        'badge-soft-success': `${staffstatus}` === 'Active',
                                        'badge-soft-warning': `${staffstatus}` === 'Inactive',
                                    }">
                                        {{ staffstatus }}
                                    </span>
                                </template>
                                <template #item-staffaction="">
                                    <div class="d-flex button-row">

                                        <b-button to="/single_staff" class="btn-sm btn-theme-dark" title="View Application">
                                            <i class="mdi mdi-eye"></i> View
                                        </b-button>

                                        <b-button class="btn-sm btn-theme-dark" title="View Application"
                                            @click="editStaff = true">
                                            <i class="mdi mdi-pencil me-1"></i>Edit
                                        </b-button>

                                        <b-button class="btn-sm btn-theme" @click="archiveStaff = true">
                                            <i class="mdi mdi-eye me-1"></i>Unarchive
                                        </b-button>
                                    </div>
                                </template>
                            </EasyDataTable>
                        </div>
                    </div>
                    <b-modal title="Un Archive Staff Member" v-model="archiveStaff" hide-footer centered>
                        <h5 class="text-center font-size-18">Do you really want to unarchive<br> this staff member? </h5>
                        <div class="d-flex justify-content-center gap-2">
                            <button class="btn btn-theme" type="button" data-bs-dismiss="modal">Yes</button>
                            <button class="btn btn-theme-dark" type="button" data-bs-dismiss="modal">No</button>
                        </div>
                    </b-modal>
                    <b-modal title="Edit Staff Member" v-model="editStaff" hide-footer centered>
                        <form>
                            <div class="d-block mb-3">
                                <label class="font-size-14">First Name </label>
                                <input type="text" class="form-control simpleInputStyle" placeholder="First Name"
                                    value="Deepak">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14">Last Name </label>
                                <input type="text" class="form-control simpleInputStyle" placeholder="Last Name"
                                    value="Sajwan">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14">Email </label>
                                <input type="email" class="form-control simpleInputStyle" placeholder="Email"
                                    value="deepak123@gmail.com">
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14">Password </label>
                                <div class="input-group">
                                    <input type="password" class="form-control simpleInputStyle" value="fetfwerwerf"
                                        placeholder="Password">
                                </div>
                            </div>
                            <div class="d-block mb-3">
                                <label class="font-size-14">Change Status </label>
                                <select class="form-select form-control simpleSelectStyle">
                                    <option selected> Active</option>
                                    <option> Inactive</option>
                                </select>
                            </div>
                            <div class="d-block text-end">
                                <input type="submit" class="btn btn-theme btn-custom-wdth" value="Save Changes">
                            </div>
                        </form>
                    </b-modal>
                </div>
            </div>
        </main>
    </Layout>
</template>